console.log('It\'s working!')

$('document').ready(init);

var $elem = {
    window: $(window),
    body: $('body'),
    hamburger: $('#Hamburger'),
    Hub: $('#Hub .scroll-container'),
    hubLink: $('.link--hub'),
    main: $('main'),
    HubContainer: $('#HubContainer'),
    ToTop: $('#ToTop'),
    MobileMenu: $('#MobileMenu'),
    Logo: $('#Logo'),
    LogoDummy: $('#LogoDummy')
}


var prevPage;
var currentPage;
var winResizeTimer;
var isMobile = $elem.window.width() < 768 ? true : false;
var mobile_header = isMobile ? $elem.MobileMenu.height() + 20 : 0;
var margin = isMobile ?  20 : 30;
var videos = [];
var players = [];
var $slick;
var canvasTimer;
var isFiltering = false;
var isInit = true;
var url = window.location;

var isMainActive = false;
var $clone;

var logoTimer;

var windowTimer;

var $stickybits;

function init(){
    initBarba();
    initMenu();
    initMainClone();
    initMobileMenu();
    initBlur();
    initTopBtn();
    initFastClick();
    initWindow();
    initLazyLoad();
    //initVideo();
    initFades();
    initHubTrigger();
    
    $('body').removeClass('init');
}

function initBarba(){
    
    // basic default transition (with no rules and minimal hooks)
    barba.init({
        timeout: 5000,
        transitions: [{
                name: 'default-crossfade',
                from: {
                    namespace: [
                        'default',
                        'search',
                        'home'
                    ]
                },
                to: {
                    namespace: [
                        'program',
                        'reflection',
                        'project'
                    ]
                },
                sync: false,
                leave({current, next, trigger}) {
                    var done = this.async();
                    isInit = false;
                    prevPage = current;
                    $('body').attr('data-referrer', current.url.href);

                    //check if content is blurred
                    //add blur filter so there is no jump
                    if($elem.body.hasClass('show-menu') || 
                       $elem.body.hasClass('blur-section-only') || 
                       $elem.body.hasClass('blur-section-only') ||
                       $elem.body.hasClass('blur-all') || 
                       $elem.body.hasClass('blur-section-only-not-close') 
                       ) {
                        $('.main-feature').addClass('removing');
                    }

                    $elem.body.removeClass('show-menu');
                    console.log('default-crossfade');

                  
                    
       
                    if($('.main-feature.cloned').length > 0) {
                        $('.main-feature.cloned').stop().animate({opacity: 0}, 400, 'linear', function(){
                            $(this).remove();
                        }); 
                    }
    
                   if($('.main-feature.clone-page').length > 0) {
                        $('.main-feature.clone-page').stop().animate({opacity: 0}, 400, 'linear', function(){
                            $(this).remove();
                        }); 
                    }
                    
                    $(current.container).stop().animate({opacity: 0}, 400, 'linear', function(){
                        
                    });

                    setTimeout(function(){
                        done();
                    }, 400);
                },
                enter({current, next, trigger}) {
                    var done = this.async();
                    console.log('default crossfade enter');
                    $(next.container).css('opacity', 0);
                    $elem.body.removeClass('blur-section-only blur-all');
                    
                    $(next.container).stop().animate({opacity: 1}, 800, 'linear', function(){
                        //alert('enter')
                        done();
                    });
                    
                    checkNav();   
                }
            },
            {
                name: 'default-crossfade-filter',
                from: {
                    namespace: [
                        'default',
                        'program',
                        'project',
                        'people',
                        'reflection',
                        'home'
                    ]
                },
                to: {
                    namespace: [
                        'default',
                        'search'
                    ]
                },
                sync: false,
                leave({current, next, trigger}) {
                    var done = this.async();
                    isInit = false;
                    prevPage = current;
                    console.log('default-crossfade-filter');
                    $('body').attr('data-referrer', current.url.href);
                    clonePage(); 
                    done();  
                },
                enter({current, next, trigger}) {
                    var done = this.async();
                    $elem.body.removeClass('blur-section-only blur-all');
                    checkNav();
                    done();  
                }
            },
            {
            name: 'crossfade',
        
            // apply only when leaving `[data-barba-namespace="home"]`
            from: {
                namespace: [
                    'program',
                    'projects',
                    'project',
                    'reflection'
                ]
            },
        
            // apply only when transitioning to `[data-barba-namespace="products | contact"]`
            to: {
              namespace: [
                'project',
                'program',
                'reflection',
                'home'
              ]
            },
        
            // apply only if clicked link contains `.cta`
            //custom: ({ current, next, trigger }) => !trigger.classList.contains('filter--tag'),
        
            // do leave and enter concurrently
            sync: false,
        
            // available hooks…
            leave({current, next, trigger}) {
                var done = this.async();
                isInit = false;
                prevPage = current;
                $('body').attr('data-referrer', current.url.href);
                console.log('crossfade')
                //check if content is blurred
                //add blur filter so there is no jump
                if($elem.body.hasClass('show-menu') || 
                    $elem.body.hasClass('blur-section-only') || 
                    $elem.body.hasClass('blur-section-only') ||
                    $elem.body.hasClass('blur-all') || 
                    $elem.body.hasClass('blur-section-only-not-close') 
                    ) {
                    $('.main-feature').addClass('removing');
                }
            
                if(isFiltering) {
                    clonePage();
                    $(current.container).stop().animate({opacity: 0}, 0, 'linear', function(){
                        done();
                        //$(this).remove();
                    });
                } else {
                    removeClonePage();
                    $elem.body.removeClass('show-menu');
                    $(current.container).stop().animate({opacity: 0}, 400, 'linear', function(){
                        done();
                        //$(this).remove();
                    });
                }
                
                
            },
            enter({current, next, trigger}) {
                var done = this.async();
                
                $(next.container).css('opacity', 0);
                $elem.body.removeClass('blur-section-only blur-all');

                if(!isFiltering) {
                    removeClonePage();
                    initSVG();
                    if($(next.container).find('.lazyload').length > 0) {
                        //has figure 
                        //wait for preload to fire then fade in
                        var isHidden = true;
                        console.log('lazy load detected')
                        $(document).off('lazyloaded').on('lazyloaded', function(e){
                            //use width of parent node instead of the image width itself
                            //alert('loaded')
                            console.log('loaded');
                            if(isHidden) {
                                isHidden = false;
                                
                                /*
                                $(next.container).animate({opacity: 1}, 800, 'linear', function(){
                                    //alert('enter')
                                    done();
                                });
                                */
                            }
                        });
                        

                        $(next.container).stop().animate({opacity: 1}, 800, 'linear', function(){
                            //alert('enter')
                            done();
                        });

                    } else {
                        $(next.container).stop().animate({opacity: 1}, 800, 'linear', function(){
                            
                            done();
                        });
                    }
                } else {
                    $('[data-barba="container"] .main-feature section').remove();
                    $(next.container).stop().animate({opacity: 1}, 0, 'linear', function(){
                        done();
                    });
                }
                
                checkNav();
                
            }
        },
        {
        name: 'no-fade',
    
        // apply only when leaving `[data-barba-namespace="home"]`
        from: {
            namespace: [
                'reflection'
            ]
        },
    
        // apply only when transitioning to `[data-barba-namespace="products | contact"]`
        to: {
          namespace: [
            'reflection',
          ]
        },
    
        // apply only if clicked link contains `.cta`
        //custom: ({ current, next, trigger }) => !trigger.classList.contains('filter--tag'),
    
        // do leave and enter concurrently
        sync: false,
    
        // available hooks…
        leave({current, next, trigger}) {
            var done = this.async();
            isInit = false;
            prevPage = current;
            $('body').attr('data-referrer', current.url.href);
            console.log('crossfade')
            //check if content is blurred
            //add blur filter so there is no jump
            if($elem.body.hasClass('show-menu') || 
                $elem.body.hasClass('blur-section-only') || 
                $elem.body.hasClass('blur-section-only') ||
                $elem.body.hasClass('blur-all') || 
                $elem.body.hasClass('blur-section-only-not-close') 
                ) {
                $('.main-feature').addClass('removing');
            }
        
            if(isFiltering) {
                clonePage();
                $(current.container).stop().animate({opacity: 0}, 0, 'linear', function(){
                    done();
                    //$(this).remove();
                });
            } else {
                removeClonePage();
                $elem.body.removeClass('show-menu');
                $(current.container).stop().animate({opacity: 0}, 0, 'linear', function(){
                    done();
                    //$(this).remove();
                });
            }
            
            
        },
        enter({current, next, trigger}) {
            var done = this.async();
            
            $(next.container).css('opacity', 0);
            $elem.body.removeClass('blur-section-only blur-all');

            if(!isFiltering) {
                removeClonePage();
                if($(next.container).find('.lazyload').length > 0) {
                    //has figure 
                    //wait for preload to fire then fade in
                    var isHidden = true;
                    console.log('lazy load detected')
                    $(document).off('lazyloaded').on('lazyloaded', function(e){
                        //use width of parent node instead of the image width itself
                        //alert('loaded')
                        console.log('loaded');
                        if(isHidden) {
                            isHidden = false;
                            
                            /*
                            $(next.container).animate({opacity: 1}, 800, 'linear', function(){
                                //alert('enter')
                                done();
                            });
                            */
                        }
                    });
                    

                    $(next.container).stop().animate({opacity: 1}, 0, 'linear', function(){
                        //alert('enter')
                        done();
                    });

                } else {
                    $(next.container).stop().animate({opacity: 1}, 0, 'linear', function(){
                        
                        done();
                    });
                }
            } else {
                $('[data-barba="container"] .main-feature section').remove();
                $(next.container).stop().animate({opacity: 1}, 0, 'linear', function(){
                    done();
                });
            }
            
            checkNav();
            
        }
    }],
        views: [{
            namespace: 'home',
            afterEnter(data) {

                $elem.main = $(data.next.container);
                currentPage = 'home';
                resetPage();
                initSlick({infinite: true, repeat: true, draggable: true});
                initHome();
                //defer video load
                $elem.window.on('load', initVideo);
                //initVideo();
                setHubLogo();
                setTemplate(currentPage);
                isFiltering = false;
                isMainActive = true;
            }
            }, {
            namespace: 'project',
            beforeEnter(data) {
                initMain();
            },
            afterEnter(data) {

                $elem.main = $(data.next.container);
                currentPage = 'project';
                resetPage();
                initSlick({infinite: false, adaptiveHeight: false});
                initProject();
                //initVideo();
                setHubLogo();
                setTemplate(currentPage);
                isFiltering = false;
                isMainActive = true;
            }
            }, {
            namespace: 'program',
            afterEnter(data) {
                console.log('init program')
                $elem.main = $(data.next.container);
                currentPage = 'program';
                resetPage();
                
                initProgram();
                initSlick();
                initVideo();
                setHubLogo();
                setTemplate(currentPage);
                isFiltering = false;
                isMainActive = true;
            } 
            }, {
                namespace: 'reflection',
                afterEnter(data) {
     
                    $elem.main = $(data.next.container);
                    currentPage = 'reflection';
                    resetPage();
                    initReflection();
                    initSlick({ adaptiveHeight: false });
                    initVideo();
                    setHubLogo();
                    setTemplate(currentPage);
                    isFiltering = false;
                    isMainActive = true;
                }
            }, {
                namespace: 'search',
                beforeEnter(data) {
                    cloneMain();
                },
                afterEnter(data) {
                    // attach content to hub container
      
                    $elem.main = $(data.next.container);
                    currentPage = 'search';
                    if(!isMobile) $('#ToTop').show();
                    initDefault();
                    initSlick();
                    initSearch();
                    checkNav();
                    setHubLogo();
                    //appendMain();
                    isFiltering = false;
                }
            }, {
            namespace: 'default',
            beforeEnter(data) {
                cloneMain();
            },
            afterEnter(data) {
                // attach content to hub container
      
                $elem.main = $(data.next.container);
                currentPage = 'default';
                if(!isMobile) $('#ToTop').show();
                initDefault();
                initSlickRTE();
                checkNav();
                setHubLogo();
                initVideo();
                //appendMain();
                isFiltering = false;
            }
        }]
    });

}

function clonePage(){
    //alert('clone page')
    if($('.clone-page').length > 0) {

    } else {
        let $clone = $('main .main-feature');
        $clone.addClass('clone-page');
        //$clone.find('.fade-in').removeClass('fade-in');
        $clone.find('.fade-in-out').removeClass('fade-in-out');
    
        $('body').append($clone);
   
    }
    
}

function removeClonePage(){
    if($('.clone-page').length > 0) {
        $('.clone-page').stop().animate({opacity: 0}, 400, 'linear', function(){
            $('.clone-page').remove();
        });
    }
}

function initSearch(){
    var input                       = $('.js-search');
    var searchResultsContainer      = $('.ajax-container');
    console.log('initSearch');
    if(input.val().length > 2) {
        $elem.main.find('form.search .close-icon').addClass('visible');
    } else {
        $elem.main.find('form.search .close-icon').removeClass('visible');
    }
    input.on('keyup', function(e) {
        if(input.val().length > 2) {
            $('form.search .close-icon').addClass('visible');
        } else {
            $('form.search .close-icon').removeClass('visible');
        }
        return false;
        // I check if the length in the input is more than 3 characters
        if(input.val().length > 2) {
            $.ajax({
                // build the url
                url: "/search/?q="+input.val()+"",
                context: jQuery('.ajax-container')
            }).done(function(data) {

                // convert the data to objects, console.log this to see 
                // how the object is build and which keys you can use
                //var results = JSON.parse(data);

                // Flush the container
                searchResultsContainer.html('');

                // loop trough the objects in results and display them
                console.log(data);
            });
        }else {
            // If there are less than 3 or 0 characters in the input, flush the container
            searchResultsContainer.html('');
        }
    });


}

function checkNav(){
    
    //if any <a href=""> in content matches top nav then make sure and reset the top nav if it's clicked
    
    $elem.Hub.find('a').each(function(){
        let $a = $(this);
        $elem.hubLink.each(function(){
            let $link = $(this);
            if($(this).attr('href') == $a.attr('href')){
                $a.on('click', function(){
                    $elem.hubLink.removeClass('active');
                    $link.addClass('active');
                });
            }
        });
    });

    $('a.active').off('click').on('click', function(e){
        e.preventDefault();
        
    });
}

function initMenu(){

    $elem.hubLink.on('click', function(){
        $elem.hubLink.removeClass('active');
        $(this).addClass('active');
    });
    $elem.Hub.on('click', function(e){
        //console.log(e.target);
        if(!$(e.target).hasClass('scroll-container') || !$(e.target).attr('id') == 'HubContainer' || !$(e.target).hasClass('form-signup')) {
            if(e.target.tagName.toLowerCase() != 'a' && 
               e.target.tagName.toLowerCase() != 'button' && 
               e.target.tagName.toLowerCase() != 'p' && 
               e.target.tagName.toLowerCase() != 'span' && 
               e.target.tagName.toLowerCase() != 'input' &&
               e.target.tagName.toLowerCase() != 'svg' &&
               e.target.tagName.toLowerCase() != 'figure' &&
               e.target.tagName.toLowerCase() != 'img' &&
               !$(e.target).hasClass('slide') && 
               !$(e.target).parent().hasClass('slick-slide')
            ) {
                console.log('close');
                $('body').removeClass('show-menu');
                $('.main-feature').removeClass('removing');
                playActiveVideos();

            }
            //console.log('close')
            //e.stopPropagation();
            //$('body').removeClass('show-menu');
        }
    });

    /*
    BackgroundCheck.init({
        targets: '#Hub',
        images: '[data-barba-namespace]'
    });
    */

    initHubTrigger();
    initBlur();
}

function initHubTrigger(){
    //console.log('initHubTrigger');

    $('.trigger-hub').off('click').on('click', function(){
        clearTimeout(logoTimer);
        $elem.body.removeClass('reveal-menu');
        $elem.body.addClass('show-menu');
        initCanvas();
        $elem.Hub.stop().animate({scrollTop: 0}, 0, 'linear');
        setHubLogo();
        pauseVideos();
    }).off('mouseover').on('mouseover', function(){
        clearTimeout(logoTimer);
        $elem.body.addClass('reveal-menu');
        logoTimer = setTimeout(function(){
            $elem.Hub.stop().animate({scrollTop: 0}, 0, 'linear');
            $elem.body.addClass('show-menu');
            initCanvas()
            pauseVideos();
        }, 1000);
    }).off('mouseout').on('mouseout', function(){
        clearTimeout(logoTimer);
        $elem.body.removeClass('reveal-menu');
    });
}

function initMainClone(){
    if($('.main-feature.cloned').length > 0) {
        $elem.main = $('.main-feature.cloned');
        initSlick();
        initHubTrigger();
    }
}

function initMobileMenu(){
    $elem.hamburger.on('click', function(){
        //console.log('click');
        $elem.body.toggleClass('show-menu');
        if($elem.body.hasClass('show-menu')) {
            $elem.Hub.stop().animate({scrollTop: 0}, 0, 'linear');
            pauseVideos();
        } else {
            playActiveVideos();
        }
    });
}

function initBlur(){
    if($('.trigger-blur').length > 0) {
        $('.trigger-blur').off('mouseover').on('mouseover', function(e){
            e.stopPropagation();
            $elem.body.addClass('blur-section-only');
            initCanvas();
        }).off('mouseout').on('mouseout', function(){
            $elem.body.removeClass('blur-section-only');
            destroyCanvas();
        });
    }

    if($('.trigger-blur-not-close').length > 0) {
        console.log('init blur')
        $('.trigger-blur-not-close').off('mouseover').on('mouseover', function(e){
            e.stopPropagation();
            $elem.body.addClass('blur-section-only-not-close');
      
        }).off('mouseout').on('mouseout', function(){
            $elem.body.removeClass('blur-section-only-not-close');
         
        });
    }

    $('.trigger-blur-all').off('mouseover').on('mouseover', function(e){
        e.stopPropagation();
        $elem.body.addClass('blur-all');
        initCanvas();
    }).off('mouseout').on('mouseout', function(){
        $elem.body.removeClass('blur-all');
        destroyCanvas();
    });
}

function initSlickRTE(){
    if($elem.HubContainer.find('.slick').length > 0) {
        console.log('====init slick======')
        var $slick_rte = $elem.HubContainer.find('.slick:not(.single-slick)');
        $slick_rte.on('init reInit afterChange', function(event, slick, currentSlide, nextSlide){
            
        }).on('mouseover mousemove', function(e){
           // let rect = $(e.target).width(),
           //     x = e.clientX - $(e.target).position().left; //x position within the element.
                //console.log($(e.target).position().left)

            let width = $(this).width();
            let x = $(this).offset().left;
            console.log(x);
            if(e.clientX > x && e.clientX < x + width/2) {
                $elem.body.removeClass('cursor-right').addClass('cursor-left');
            }
            if(e.clientX > x + width/2 && e.clientX < x + width) {
                $elem.body.removeClass('cursor-left').addClass('cursor-right');
            }
            if(e.clientX < x || e.clientX > x + width) {
                $elem.body.removeClass('cursor-left cursor-right');
            }
            /*
            if(x > rect/2) {
                console.log('right')
                $('body').removeClass('cursor-left').addClass('cursor-right');
            } else {
                console.log('left')
                $('body').addClass('cursor-left').removeClass('cursor-right');
            }
            */
        }).on('mouseout', function(e){
            console.log('remove cursor')
            $elem.body.removeClass('cursor-left cursor-right');
        });


        $slick_rte.slick({
        dots: false,
        arrow: true,
        easing: 'linear',
        prevArrow: $('.slick--arrow-left'),
        nextArrow: $('.slick--arrow-right'),
        speed: 450,
        swipe: true,
        mobileFirst: true,
        infinite: true,
        adaptiveHeight: false
    });
    $slick_rte.off('click').on('click', function(e){
        let $s = $(this);
        if(e.target.tagName.toLowerCase() == 'a') return true;
        let rect = e.target.getBoundingClientRect(),
            x = e.clientX - rect.left; //x position within the element.
            y = e.clientY - rect.top;  //y position within the element.
        if(x > rect.width/2) {
            //$(this).slick('next');
            if($s[0].slick.currentSlide == ($s[0].slick.slideCount -1 )) {
                $s[0].slick.options.speed = 0;
                $s.slick('slickGoTo', 0);
                $s[0].slick.options.speed = 450;
            } else {
                $s[0].slick.options.speed = 0;
                $s.slick('next');
                $s[0].slick.options.speed = 450;
            }
        } else {
            if($s[0].slick.currentSlide == 0) {
                $s[0].slick.options.speed = 0;
                //$s.slick('slickGoTo', ($s[0].slick.slideCount ));
                $s.slick('prev');
                $s[0].slick.options.speed = 450;
                
            } else {
                $s[0].slick.options.speed = 0;
                $s.slick('prev');
                $s[0].slick.options.speed = 450;
            }
        }
    });

    }
}

function initSlick(options={}){
    console.log(options);
    var slickTimer;
    if($elem.main.find('.slick').length > 0) {
            $slick = $elem.main.find('.slick:not(.single-slick)'),
            $counter = $elem.main.find('.slick--counter');
            $slick.on('beforeChange', function(){
                clearTimeout(slickTimer);
                $elem.body.addClass('slick-changing');
            }).on('init reInit afterChange', function(event, slick, currentSlide, nextSlide){
            //currentSlide is undefined on init -- set it to 0 in this case (currentSlide is 0 based)
            var i = (currentSlide ? currentSlide : 0) + 1;
            $counter.text(i + '/' + slick.slideCount);
            playActiveVideos();

            let $this = $(this);

            //set custom caption if it exists
            if($this.find('.slick-active img[data-caption]').length > 0) {
                let $img = $this.find('.slick-active img[data-caption]');
                let $caption = $this.parent().find('.module--caption');
                if($img.attr('data-caption') !== '' && $img.attr('data-caption') != undefined) {
                    console.log('change to custom caption')
                    //add link after default caption
                    //hide default caption
                    let newCaption;
                    if($img.attr('data-page-link') !== '' && $img.attr('data-page-link') != undefined) {
                        newCaption = `<a class="custom-caption" href="/${$img.attr('data-page-link')}"><span>${$img.attr('data-caption')}</span></a>`;
                    } else {
                        newCaption = `<span class="custom-caption">${$img.attr('data-caption')}</span>`;
                    }
                    
                    $caption.find('.module--caption-object').hide();
                    $caption.append(newCaption);
                } else {
                    console.log('change to default caption');
                    $caption.find('.custom-caption').remove();
                    $caption.find('.module--caption-object').show();
                }
            }
            
            slickTimer = setTimeout(function(){
                $elem.body.removeClass('slick-changing');
            }, 150);
           
            /*
            if($(this).find('.slick-active .video-js').length > 0) {
                console.log('========= video found =======');

                if($(this).find('.slick-slide:not(.slick-active) .video-js').length > 0) {
                    $(this).find('.slick-slide:not(.slick-active) .video-js').each(function(){
                        let _id = $(this).attr('id');
                        console.log($(this))
                        //$(this)[0].pause();
                    })
                }

                let id = $slick.find('.slick-active .video-js').attr('id');
                let v = videojs.getPlayer(id).ready(function(){
                    //this.play();
                })
                
                
            }
            */

            if($slick.find('.slick-active .project--slide').length > 0)
                setTextColor($slick.find('.slick-active .project--slide').data('text-color'));
        }).on('mouseover mousemove', function(e){
            let rect = e.target.getBoundingClientRect(),
                x = e.clientX - rect.left; //x position within the element.
                y = e.clientY - rect.top;  //y position within the element.
            if(x > rect.width/2) {
                $('body').removeClass('cursor-left').addClass('cursor-right');
            } else {
                $('body').addClass('cursor-left').removeClass('cursor-right');
            }
        }).on('mouseout', function(e){
            $('body').removeClass('cursor-right cursor-left');
        });
        $slick.slick({
            dots: false,
            arrow: true,
            easing: 'linear',
            prevArrow: $('.slick--arrow-left'),
            nextArrow: $('.slick--arrow-right'),
            speed: 450,
            swipe: true,
            mobileFirst: true,
            infinite: options.infinite != undefined ? options.infinite : true,
            adaptiveHeight: false
        });

        
        $slick.off('click').on('click', function(e){
            let $s = $(this);
            if(e.target.tagName.toLowerCase() == 'a') return true;
            let rect = e.target.getBoundingClientRect(),
                x = e.clientX - rect.left; //x position within the element.
                y = e.clientY - rect.top;  //y position within the element.
            if(x > rect.width/2) {
                //$(this).slick('next');
                if($s[0].slick.currentSlide == ($s[0].slick.slideCount -1 )) {
                    $s[0].slick.options.speed = 0;
                    $s.slick('next');
                    $s[0].slick.options.speed = 450;
                    //$s.slick('slickGoTo', 0);
                } else {
                    $s[0].slick.options.speed = 0;
                    $s.slick('next');
                    $s[0].slick.options.speed = 450;
                }
            } else {
                if($s[0].slick.currentSlide == 0) {
                    //$s.slick('slickGoTo', ($s[0].slick.slideCount ));
                    $s[0].slick.options.speed = 0;
                    $s.slick('prev');
                    $s[0].slick.options.speed = 450;
                } else {
                    $s[0].slick.options.speed = 0;
                    $s.slick('prev');
                    $s[0].slick.options.speed = 450;
                }
            }
        });

    }

    function setTextColor(color){
        if(color == undefined ) var color = 'black';
        if(color == 'black' || color == '') {
            //set body text color black
            $('body').attr('data-text-color', '');
        } else {
            $('body').attr('data-text-color', color);
        }

    }
}

function initDefault(){
    console.log('init default');
    let $rte = $elem.main.find('.rte');
    if(isFiltering || $elem.HubContainer.find('[data-template="person"]').length > 0) $rte.removeClass('fade-in');
    initRTE($rte);
    
    if($elem.HubContainer.find('.newsletter').length > 0) {
        //mailchimp signup
        initMailChimp();
    }
    setTimeout(function(){
        $elem.HubContainer.find('.filter--tag').off('click').on('click', function(){
            isFiltering = true;
        });
    }, 10);

    initHubTrigger();
    
}

function pushPeople($active){
    console.log(isMobile);
    if(isMobile) {
        var $bio = $($active);
        $('.list-li.active').css('padding-bottom', '');
    } else {
        var $bio = $($active);
        var offset = $bio.outerHeight();
        $('.list-li.active').css('padding-bottom', offset);
    }
    
    return true;
    var start = $('.list-li.active').index();
    var len = $('.list-li').length; 
    console.log($bio.position().top)
    console.log('start from ' + start);
    console.log('total people ' + len);
    for(var i=start; i<len; i++) {
        let $person = $('.list-li').eq(i);
        console.log(i);
        //see if bio overlaps people below. If so push people below by bio outerheight
        if($person != undefined) {
            if($bio.position().top + 10 <= $person.position().top) {
                //$person.css('padding-top', offset);
            }
        }
        
    }
}

function initRTE(html) {
    //fade out rte
    //fade in new rte

    console.log('init rte');

    if($elem.HubContainer.find('.rte').length > 0) {
        $elem.HubContainer.find('.rte').addClass('remove');
        $elem.HubContainer.append(html);
        if($elem.HubContainer.find('.rte.fade-in').length > 1) {
            $elem.HubContainer.find('.rte').first().addClass('fade-out-rte');
        }
        setTimeout(function(){
            $elem.HubContainer.find('.rte.remove').remove();
            $elem.Logo.show();
                $elem.LogoDummy.show();
                setHubLogo();
            /*
            if($('[data-template="studios"]').length > 0) {
                $elem.Logo.hide();
                $elem.LogoDummy.hide();
            } else {
                $elem.Logo.show();
                $elem.LogoDummy.show();
                setHubLogo();
            }
            */
        }, 250);
    } else {
        //fade in rte
        $elem.HubContainer.append(html);
    }

    //init studio
    if($('[data-template="studios"]').length > 0) {
        console.log('set svg height')
        $('[data-template="studios"] svg').css('height', $elem.window.width() * .25); 
    } 

    if($elem.HubContainer.find('.lockup').length > 0) {
        initLockup($elem.HubContainer.find('.lockup'));
    }

    if($('[data-template="studios"]').length > 0) {
        
    } else {
        $elem.Logo.show();
        $elem.LogoDummy.show();
        setHubLogo();
    }

    

    //init Person
    if($('[data-template="person"]').length > 0) {
        let $lastRTE = $elem.HubContainer.find('.rte:last-child');
        
        if($('.list-li.active').length > 0) {
            //active accordion
            var $active_accordion = $lastRTE.find('.list-li.active .list--accordion');
            $active_accordion = $lastRTE.find('.list-li.active .list--accordion');
            console.log($active_accordion.outerHeight());
            pushPeople($active_accordion);
            $elem.Hub.animate({scrollTop: $elem.Hub.scrollTop() +  $lastRTE.find('.list-li.active').position().top - ($elem.MobileMenu.outerHeight()*1.1) })
            
        }
    } else {

    }

    

    //login
    /*
    if($('.login-form').length > 0) {
        var $form = $('.login-form'),
            url = $form.attr( 'action' );
        $form.on('submit', function(e){
            e.preventDefault();
            var postData = { email: $form.find('#email').val(), password: $form.find('#password').val() }
            $.ajax({
                type: "post",
                url: "http://localhost:8080/login",
                data: postData,
                contentType: "application/x-www-form-urlencoded",
                success: function(responseData, textStatus, jqXHR) {
                    alert("data saved")
                },
                error: function(jqXHR, textStatus, errorThrown) {
                    console.log(errorThrown);
                }
            })
        });
    }
    */
    
    $elem.body.addClass('show-menu');
    setHubLogo();
    initTopBtn();
}

var hubTimer;

function initLockup($lockup) {
    console.log($lockup);
    let $lockups = [];
    for(let i=0; i<$lockup.find('.lockup--list').length; i++) {
        let index = i;
        let $lockupSlick = $lockup.find('.lockup--list').eq(index).slick({
            autoplay: i == 0 ? true : false,
            speed: 2000,
            arrows: false,
            dots: false,
            autoplaySpeed: 0
        });

        $lockups.push($lockupSlick);

        
    }

    $lockups[0].on('afterChange', function(){
        console.log('change bottom lockup');
        setTimeout(function(){
            $lockups[1].slick('next');
        }, 1000);
    });
}

function setHubLogo(){

    if($elem.LogoDummy.position().top + $elem.Hub.scrollTop() > $elem.window.innerHeight() - $elem.LogoDummy.height()){
        //console.log('under');
        $elem.Logo.css('top', $elem.Hub.scrollTop() + $elem.LogoDummy.position().top + $elem.MobileMenu.height() - 30 );
        $elem.Logo.addClass('delay');
    } else {
        //console.log('bottom')
        $elem.Logo.css('top',  window.innerHeight - $elem.Logo.find('svg').outerHeight() );
        $elem.Logo.removeClass('delay');
    }

    $elem.Logo.on('click', function(e){
        if($('[data-template="home"]').length > 0) {
            e.preventDefault();
        }
    });

    clearTimeout(hubTimer);

    hubTimer = setTimeout(function(){
        if($elem.LogoDummy.position().top + $elem.Hub.scrollTop() > $elem.window.innerHeight() - $elem.LogoDummy.height()){
            //console.log('under');
            $elem.Logo.css('top', $elem.Hub.scrollTop() + $elem.LogoDummy.position().top + $elem.MobileMenu.height() - 30 );
            $elem.Logo.addClass('delay');
        } else {
            //console.log('bottom')
            $elem.Logo.css('top',  window.innerHeight - $elem.Logo.find('svg').outerHeight() );
            $elem.Logo.removeClass('delay');
        }
   }, 100);

   
    
}

function initHome(){
    if(isInit) $('#ToTop').hide();
    $('[data-template="home"]').css('height', $elem.window.height());
    console.log('init Home');
    $elem.body.attr('data-body-template', 'home');
    if($('.main-feature.clone-page').length > 0) {
        removeClonePage();
        setTimeout(function(){
            clonePage();
        }, 400);
    }
    if($('.main-feature:not(.clone-page)').length == 1) {
        clonePage();
    } 
    
    $('[data-fade-in]').addClass('fade-in');

    
    /*
    $('video').each(function(){
        let $video = $(this);
        $video[0].play();
        console.log('========== play =========')
        $video.on("canplay", function(){
            $video[0].play();
            console.log('loadstart can play')
        });
        document.querySelector('video').addEventListener('ended', function () {
            console.count('loop restart');
            this.play();
          })
    });
    */

    
}

function initMailChimp() {
    console.log('mailchjimp')
    let $form = $elem.HubContainer.find('.newsletter'),
        $submit = $form.find('submit'),
        $email = $form.find('input[type="email"]'),
        $trigger = $elem.HubContainer.find('.form-signup');

        $trigger.on('click', function(){
            $form.addClass('active');
            $trigger.hide();
        });

        $('form.newsletter button[type="submit"]').bind('click', function ( event ) {
            if ( event ) event.preventDefault();
            // validate_input() is a validation function I wrote, you'll have to substitute this with your own.
            register($form);
        });
}

function register($form) {
    $.ajax({
        type: $form.attr('method'),
        url: $form.attr('action'),
        data: $form.serialize(),
        cache       : false,
        dataType    : 'json',
        contentType: "application/json; charset=utf-8",
        error       : function(err) { console.log("Could not connect to the registration server. Please try again later."); },
        success     : function(data) {
            console.log(data);
            if (data.result != "success") {
                // Something went wrong, do something to notify the user. maybe alert(data.msg);
                $form.find('.form-message').html(data.msg);
            } else {
             
                //$form.find('.form-message').text('Thank you for signing up');
                let $message = $form.find('.form-message');
                let $wrapper = $form.find('.form-input');
                $wrapper.hide();
                $message.text('Thank you for signing up');

                setTimeout(function(){
                    $message.fadeOut();
                }, 2000);
                // It worked, carry on...
            }
            $elem.window.trigger('resize');
        }
    });
}

function initReflection(){
    let $close = $elem.main.find('.close-icon');

    //alert('init reflection')
    let $rte = $elem.main.find('.reflection-list');
        if(isFiltering) $rte.removeClass('fade-in');
        $elem.HubContainer.html($rte);
        $rte.css('display', '');

    setTimeout(function(){
        $elem.HubContainer.find('.filter--tag').off('click').on('click', function(){
            isFiltering = true;
        });
    }, 10);

    setTimeout(function(){
        $elem.main.find('.reflection-blocks').addClass('fade-in-normal');
    }, 250);

    $('.article--prev, .article--next').on('mouseover', function(e){
        let text = $(this).find('a').text(); 
        $(this).parent().append(`<div class="article-caption">${text}</div>`);
        let x = e.clientX - $('.article-caption').width()/2 + 24,
            y = e.clientY + 57;
            $('.article-caption').css('transform', `translate(${x}px, ${y}px)`);
    }).on('mousemove', function(e){
        let x = e.clientX - $('.article-caption').width()/2 + 24,
            y = e.clientY + 57;
        $('.article-caption').css('transform', `translate(${x}px, ${y}px)`);
    }).on('mouseout', function(){
        $('.article-caption').remove();
    });



    $close.on('click', function(e){
        e.preventDefault();
        if(!isInit) {
            $(this).addClass('no-pointer');
            //if previous page is a project page open hub otherwise go back
            if($elem.body.attr('data-referrer') != undefined && $elem.body.attr('data-referrer').indexOf('reflections/') > 0) {
                $elem.body.addClass('show-menu');
                $elem.Hub.stop().animate({scrollTop: 0}, 0, 'linear');
            } else {
                javascript:history.back();
            }
            
        } else {
            $elem.body.addClass('show-menu');
            $elem.Hub.stop().animate({scrollTop: 0}, 0, 'linear');
        }
    });

    //$elem.main.find('.slick').css('height', $elem.main.find('.slick').width() * .75);
    
    $elem.window.on('scroll', $.throttle(300, reflectionScroll));
    var len = $elem.main.find('.program--module').length - 1; 
    function reflectionScroll(){
        let st = $elem.window.scrollTop();
        for(var i=0; i<len; i++) {
            if(st >= $('.program--module').eq(i).position().top + $('.program--module').eq(i).height() - $elem.window.height()) {
                $('.program--module').eq(i).addClass('in-view');
            }
        }
    }

    // look for any in view on load
    setTimeout( reflectionScroll, 150);
}

function initProgram(){
    let headerHeight = 0,
        $currHeader = undefined,
        $nextQuestion = undefined;
    
    let $rte = $elem.main.find('.program-list');
    let $footer = $elem.main.find('.menu--footer');
    
    

    $footer.addClass('fade-in-normal');
    $('.module--svg-text').addClass('fade-in-normal');

    initDataSwitch();
    
    if(isFiltering) $rte.removeClass('fade-in');
        $elem.HubContainer.html($rte);
        $rte.css('display', '');

    setTimeout(function(){
        $elem.HubContainer.find('.filter--tag').off('click').on('click', function(){
            isFiltering = true;
        });
        
    }, 10);

    setTimeout(function(){
        $('.no-hover').removeClass('no-hover');
        videoScroll();
    }, 2000);

    

    $elem.main.find('.main-feature').addClass('visible');

    
    if($stickybits != undefined) $stickybits.cleanup();
    $stickybits = stickybits($elem.main.find('.program--module-question'), {useStickyClasses: true})
    $stickybits.update();
    setTimeout(function(){
        $stickybits.update();
    }, 1000);
    setTimeout(function(){
        $stickybits.update();
    }, 2000);
    

    var len = $elem.main.find('.program--module').length - 1; 
    var row_len = $elem.main.find('.program--row').length; 

    $elem.window.on('scroll', $.throttle(300, programScroll));
    function programScroll(){
        let st = $elem.window.scrollTop();
        for(var i=0; i<len; i++) {
            if(st >= $('.program--module').eq(i).position().top + $('.program--module').eq(i).height() - $elem.window.height() + $elem.window.height()/6) {
                $('.program--module').eq(i).addClass('in-view');
            }
        }
        if(st + $elem.window.height() > $(document).height() - 100) {
            $('.program--module').addClass('in-view');
        }
    }

    //look for any in view on load
    setTimeout( programScroll, 150);

    

    
    /*
    var raf = window.requestAnimationFrame ||
        window.webkitRequestAnimationFrame ||
        window.mozRequestAnimationFrame ||
        window.msRequestAnimationFrame ||
        window.oRequestAnimationFrame;
    var $window = $(window);
    var lastScrollTop = $window.scrollTop();
    var $questions = $elem.main.find('.program--module-question').map(function(index, elem){
        return elem;
    });
    //$questions = $questions.slice(0, -1);
    console.log($questions);

       
    var scroll = function () {
        // do the onscroll stuff you want here
        let st = lastScrollTop;
            for(var i=0; i<$questions.length; i++) {
                let $question = $($questions[i]);
                if($nextQuestion != undefined) {
                    if(st >= $nextQuestion.position().top - headerHeight){
                        $currHeader.addClass('move');
                        let pos = -st + $nextQuestion.position().top - headerHeight;
                        $currHeader.find('h2').css({ top: pos })
                        $currHeader.css('transform', 'translate3D()')
                        
                    } else {
                        $currHeader.find('h2').css({top: ''});
                    }
                }
                if(st >= $question.position().top - mobile_header) {
                    //$('.program--module-question h2').removeClass('fixed');
                    $currHeader = $question;
                    $currHeader.addClass('fixed');
                    headerHeight = $currHeader.find('h2').outerHeight();
                    if(i != $questions.length-1) {
                        $nextQuestion = $question.nextAll('.program--module-question').first();
                    } else {
                        $nextQuestion = undefined;
                    }
                    
                   
                } else {
                    $question.removeClass('fixed');
                    //$question.find('h2').css('');
                    //$question.css('')
                }
                
            }
            return true;
            $questions.each(function(){
            let $question = $(this);
            if($nextQuestion != undefined && $nextQuestion.length > 0) {
                if(st >= $nextQuestion.position().top - headerHeight){
                    $currHeader.addClass('move');
                    $currHeader.find('h2').css({
                        top: -st + $nextQuestion.position().top - headerHeight
                    })
                } else {
                    $currHeader.find('h2').css({top: ''});
                }
                
                //console.log(headerHeight)
            }
            if(st >= $question.position().top - mobile_header) {
                //$('.program--module-question h2').removeClass('fixed');
                $currHeader = $question;
                $currHeader.addClass('fixed');
                $nextQuestion = $question.nextAll('.program--module-question').first();
                headerHeight = $currHeader.find('h2').outerHeight();
            } else {
                $question.removeClass('fixed');
                //$question.find('h2').css('');
                //$question.css('')
            }
        });
    };
    
    
    $elem.window.on('scroll', function(){
        
        if (raf) {
            loop();
        }
    });
    
    function loop() {
        var scrollTop = $window.scrollTop();
        if (lastScrollTop === scrollTop) {
            raf(loop);
            return;
        } else {
            lastScrollTop = scrollTop;
    
            // fire scroll function if scrolls vertically
            scroll();
            raf(loop);
        }
    }
    */
}

function initProject(){
    let $rte = $elem.main.find('.project-list');
    let $title = $elem.main.find('.project--title');
    let $banner_title = $elem.main.find('.banner--title');
    let $counter = $elem.main.find('.slick--counter');
    let $close = $elem.main.find('.close-icon');
    let $footer = $elem.main.find('.menu--footer');

    //fix weird bug page scroll issue where body is halfway
    $elem.body.scrollTop(0);


    //$elem.body.addClass('loading');
    
    /*
    if(prevPage != undefined) {
        console.log('<<<<<<<<<<<<<<<')
        console.log(prevPage);
        let title = prevPage.url.path;

        if(~title.indexOf('/programs/')) {
            //set program footer URL to previous program page
            title = title.split('/programs/').join('');
            title = title.split('-').join(' ');
            let $footer_a = $footer.find('a');
            $footer_a.attr('href', prevPage.url.href);
            $footer_a.text(title);
        }
        
    }
    */

    $elem.HubContainer.html($rte);
    $rte.css('display', '');
    
    initDataSwitch();

    $elem.HubContainer.find('.filter--tag').on('click', function(){
        isFiltering = true;
    });

    setTimeout(function(){
        $('.no-hover').removeClass('no-hover');
    }, 500);

    $close.on('click', function(e){
        e.preventDefault();
        //if previous page is a project page open hub otherwise go back
        if($elem.body.attr('data-referrer') != undefined && $elem.body.attr('data-referrer').indexOf('projects/') > 0) {
            $elem.body.addClass('show-menu');
            $elem.Hub.stop().animate({scrollTop: 0}, 0, 'linear');
        } else {
            javascript:history.back();
        }
    });

    clearInterval(windowTimer);
    windowTimer = setInterval(function(){

        //slick initialized
        if($elem.main.find('.slick-initialized').length > 0) {

            //if first image loaded
            if($elem.main.find('.slick-slide:first-child figure.lazyloaded').length > 0) {

                console.log('======= project image loaded =======');
                //image loaded proceed
                $title.addClass('fade-in-normal');
                $counter.addClass('fade-in-normal');
                $footer.addClass('fade-in-normal');
                $elem.body.removeClass('loading');
                //$close.addClass('fade-in-normal');
                clearInterval(windowTimer);
            }
        }
    }, 250);

    $('[data-barba-namespace="project"]').css('height', $elem.window.innerHeight());
    
    
    /*
    $slick.off('beforeChange').on('beforeChange', function(event, slick, currentSlide, nextSlide){
        console.log(nextSlide)
        if(nextSlide > 0) {
            $title.addClass('fade-in-normal');
            $counter.addClass('fade-in-normal');
            $close.addClass('fade-in-normal');
            $footer.addClass('fade-in-normal');
            $banner_title.hide();
        } else {
            //$title.hide();
        }
    });
    */

    $('.slick-slider').on('click', '.slide', function(e){
        //at the end of the slideshow open the hub
        if(e.clientX >= $elem.window.width()/2 && $slick[0].slick.currentSlide + 1 == $slick[0].slick.$slides.length) {
            //$close.click();
            //$slick.slick('goTo', $slick[0].slick.$slides.length);
            $('.trigger-hub').trigger('click');
        }
    });
}

function initMain(){
    //alert(isMainActive);
}

function initTopBtn(){
    if($('.ToTop').length > 0)
    $('.ToTop').off('click').on('click', function(){
        console.log('click')
        $elem.Hub.stop().animate({scrollTop: 0}, 200, 'linear')
    });
}

function initDataSwitch(){
    if($('[data-switch]').length > 0) {
        $('[data-switch]').off('click').on('click', function(e){
            e.stopPropagation();
            let $this = $(this);
            let $scrollContainer = $this.closest('.project--slide');
            let val = $(this).attr('data-switch');
            console.log('clicked');
            $('[data-switch-content]').hide();
            $(`[data-${val}]`).show();
            $scrollContainer.animate({scrollTop: 0}, 250, 'linear');
        });
    }
}

function setTemplate(page) {
    $elem.body.attr('data-body-template', page);
}

function resetPage(){
    // reset vars
    console.log('reset page');
    $('body, html').scrollTop(0);
    $('body').removeClass('cursor-right cursor-left');
    $elem.body.attr('data-body-template', '');
    /*
    if($('[data-barba-namespace="program"]').length == 0){
        $elem.body.removeClass('show-menu blur');
    }*/
    if(!isInit && !isMobile){
        $('#ToTop').show();
    } else {
        //$('#ToTop').hide();
    }

    $elem.window.off('resize', pushPeople);
    $elem.window.off('scroll');

    if(!isFiltering) $elem.body.removeClass('show-menu blur blur-section-only blur-all'); 
    
    $('body').attr('data-text-color', '');

    if($('.cloned').length > 0){
        $('.cloned').remove();
    }

    // reset event listeners
    initBlur();

    if($('.trigger-hub').length > 0) {
        initHubTrigger();
    }

    initTopBtn();
}

function playActiveVideos(){
    console.log('playActiveVideos')
    if($('body').hasClass('show-menu')) {
        if($('video').length > 0) {
            $('video').each(function(){
                let $this = $(this);
                $this[0].pause();
            });
        }
       return true; 
    } 
    if($('video').length > 0) {
        //console.log('======= play active videos =======')
        if( $('[data-barba-namespace="home"]').length > 0 || $('[data-barba-namespace="project"]').length > 0 ) {
            $('video').each(function(){
                let $this = $(this);

                
                
                if(!$this.closest('.slick-slide').hasClass('slick-active')) {
                    $this[0].pause();
                } else {
                    //load video if not already loaded
                    loadVideo($(this));
                    if($this.closest('.slick-slide.slick-active').length > 0) {
                        
                        let nextSlick = $this.closest('.slick-slide.slick-active').next();
                        let nextVideo = nextSlick.find('video');
                        
                        if(nextVideo.length > 0) loadVideo(nextVideo);
                        
                    }
                    //console.log('play this video')
                }
            });
        } else {
            videoScroll();
            //console.log('video scroll');
        }
    }
}

function loadVideo($video) {
   
    if(!$video.hasClass('video-lazy-loaded')) {
        //console.log('loadVideo')
        $video.find('source').each(function(){
            let src = $(this).attr('data-src');
            $(this).attr('src', src);
            $(this).removeAttr('data-src');
        });

        let poster = $video.attr('data-poster');
        $video.attr('poster', poster);
        $video.removeAttr('data-poster');

        $video[0].load();
        $video.addClass('video-lazy-loaded');

        $video.on('canplay', function(){
            let $this = $(this);
            //console.log('can play')
            if(!$this.closest('.slick-slide').hasClass('slick-active')) {
                $this[0].pause();
            } else {
                if(!$('body').hasClass('show-menu')) {
                    $this[0].play();
                } else {
                    $this[0].pause();
                }
            }
        }).on('ended', function(){
            let $this = $(this);
            console.log('end this video');
            let $slick = $(this).closest('.slick');

            //pause videos inside slick slideshow
            //used for homepage
            if($slick.length > 0) {
                if($slick[0].slick.currentSlide == ($slick[0].slick.slideCount -1 )) {
                    $slick.slick('slickGoTo', 0);
                } else {
                    $slick.slick('next');
                }
            } else {
                console.log('play again')
                $this[0].play();
            }
            
            if(!$('body').hasClass('show-menu')) {
                if($slick.length > 0) { 
                    $slick.find('.slick-active video')[0].play();
                } else {
                    $this[0].play();
                }
            } else {
                if($slick.length > 0) { 
                    $slick.find('.slick-active video')[0].pause();
                }
            }
        });
    } else {
        if(!$elem.body.hasClass('show-menu')) $video[0].play();
    }
}

function initSVG(){

    var defaults = {
        "resize": true,
        "size": [17, 17]
    }

    $('.module--svg-text').each(function(){
        let $svg = $(this),
            $rect = $svg.find('rect'),
            $text = $svg.find('text'),
            isLandscape = $svg.hasClass('landscape'),
            _id = $text.attr('id'),
            parent_w = $svg.closest('.slick').length > 0 ?  $svg.closest('.slick').width() : $svg.closest('.program--module').width(),
            parent_h = $svg.closest('.slick').length > 0 ?  $svg.closest('.slick').height() : $svg.closest('.program--module').height();

            if(isLandscape){
                $svg.attr('width', 450).attr('height', 337);
                defaults.width  =  450;
                defaults.height =  337;
                s = parent_w/450;
                $svg.css('transform', `scale(${s})`);
            } else {
                $svg.attr('width', 337).attr('height', 450);
                $rect.attr('width', 450).attr('height', 450);
                defaults.width  =  337;
                defaults.height =  450;
                s = parent_w/337;
                $svg.css('transform', `scale(${s})`);
            }
            
         
            let s = (ww/parent_w);
            //defaults.size = [s, s];
            


        d3plus.textwrap()
          .config(defaults)
          .text($text.data('text'))
          .container(d3.select('#' + _id))
          .draw();

          

    });


    return true;
    let margin = $(window).width() > 768 ? 20 : 15;
    let offset = margin*2;
    //let size = $(window).width() > 768 ? $(window).width() * .0165 : 12;
    let size = 17;
    var defaults = {
        "resize": true,
        "size": [size, size]
      }

      var ww = $elem.window.width();
      
      $('.module--svg-text').each(function(){
          let $svg = $(this),
              $rect = $svg.find('rect'),
              $text = $svg.find('text'),
              isLandscape = $svg.hasClass('landscape'),
              _id = $text.attr('id'),
              parent_w = $svg.closest('.slick').length > 0 ?  $svg.closest('.slick').width() : $svg.closest('.program--module').width(),
              parent_h = $svg.closest('.slick').length > 0 ?  $svg.closest('.slick').height() : $svg.closest('.program--module').height();

              //console.log($svg.closest('.program--module').height());
              $svg.attr('width', 132).attr('height', 176);
              //$svg.attr('width', 132).attr('height', 176);
              //$rect.attr('width', $svg.parent().width()).attr('height', parent_h);
              //$text.attr('width', $svg.parent().width()).attr('height', parent_h);
           
              let s = (ww/parent_w);
              //console.log(s);
              defaults.size = [s, s];
              defaults.width  =  $svg.width();
              defaults.height =  $svg.height();


          d3plus.textwrap()
            .config(defaults)
            .text($text.data('text'))
            .container(d3.select('#' + _id))
            .draw();

            //$text.attr('font-size', s + 'rem');
            //$text.css('font-size', s + 'px');

            s = parent_w/132 * 1.05;
            console.log('parent_w ' + parent_w);
            $svg.css('transform', `scale(${s})`);

      });
      
      


      return true;
     
      $('.rectWrap').each(function(){
        let id = $(this).attr('id'),
            $this = $(this),
            $svg = $this.parent(),
            isLandscape = $svg.hasClass('landscape'),
            w =  Number($svg.width()),
            h = Number($svg.attr('height')),
            s = 132;
        

            console.log('size ' + size);

        defaults.width  =  132;
        defaults.height =  176;
        //defaults.size = [size, size];

        d3plus.textwrap()
        .config(defaults)
        .text($this.data('text'))
        .container(d3.select('#' + id))
        .draw();

        //return true;

        $('.shape').each(function(){
            let $this = $(this),
                $svg = $this.parent(),
                $text = $svg.find('text'),
                isLandscape = $svg.hasClass('landscape'),
                s = $svg.parent().width()/132;
                
                $svg.css('transform', `scale(${s})`);
        });
    });

    

    
}

function sizeSVG(){
    return true;
    $('.module--svg-text').each(function(){
        let $svg = $(this),
            $parent = $svg.closest('.slick').length > 0 ? $svg.closest('.slick') : $svg.closest('.rte'),
            w = $parent.width() > 176 ? 176 : $parent.width(),
            h = Math.ceil($parent.height()/132),
            $shape = $svg.find('.shape');

            //console.log($parent.width());
            $svg.attr('width', w);
            $svg.attr('height', h);

            $shape.attr('width', w);
            $shape.attr('height', h);
    });


    
}

function initVideo(){
    
    if($('video').length > 0) {
        console.log('init videos');
        objectFitVideos();
        $('video').each(function(){
            let $this = $(this);

            if($(this).closest('.slick-active').length > 0 || $(this).closest('.module--video').length > 0) {
                loadVideo($this);
            } else {
                $this.on('canplay', function(){
                    let $this = $(this);
                    //console.log('can play')
                    if(!$this.closest('.slick-slide').hasClass('slick-active')) {
                        $this[0].pause();
                    } else {
                        if(!$('body').hasClass('show-menu')) {
                            $this[0].play();
                        } else {
                            $this[0].pause();
                        }
                    }
                }).on('ended', function(){
                    let $this = $(this);
                    console.log('end this video');
                    let $slick = $(this).closest('.slick');
    
                    //pause videos inside slick slideshow
                    //used for homepage
                    if($slick.length > 0) {
                        if($slick[0].slick.currentSlide == ($slick[0].slick.slideCount -1 )) {
                            $slick.slick('slickGoTo', 0);
                        } else {
                            $slick.slick('next');
                        }
                    } else {
                        console.log('play again')
                        $this[0].play();
                    }
                    
                    if(!$('body').hasClass('show-menu')) {
                        if($slick.length > 0) { 
                            $slick.find('.slick-active video')[0].play();
                        } else {
                            $this[0].play();
                        }
                    } else {
                        if($slick.length > 0) { 
                            $slick.find('.slick-active video')[0].pause();
                        }
                    }
                });
            }

            
            
        })
        
    }

    if($('.module--video').length > 0) {
        $elem.window.off('scroll', videoScroll);
        $elem.window.on('scroll', $.throttle( 300, videoScroll ));
    }
    
    return true;
    if($('.module--video').length > 0) {
        players = [];
        

       // players = Array.from(document.querySelectorAll('.js-player')).map(p => new Plyr(p));

        //videos.push(player);
        //player.autoplay = true;
        $elem.window.off('scroll', videoScroll);
        $elem.window.on('scroll', $.throttle( 300, videoScroll ));
    }
    if($('.vimeo-embed').length > 0) {
        var player_options = {
            muted: true
        }
        $('.vimeo-embed').each(function(){
            var player = new Plyr($(this), player_options);
            player.setVolume(0);
        })
        
    }

    if($('.video-js').length > 0) {
        var options = {
            loop: false,
            muted: true,
            autoplay: true
        };

        $('.video-js').each(function(){
            let id = $(this).attr('id');
            let $this = $(this);
            var player = videojs(id, options, function onPlayerReady() {
                    videojs.log('Your player is ready!');
        
                    // In this context, `this` is the player that was created by Video.js.
                    if(!$this.closest('.slick-slide').hasClass('slick-active')) {
                        this.pause();
                    } else {
                        this.pause();
                        this.autoplay('muted');
                        console.log('play this video')
                    }
                    
            
                    // How about an event listener?
                    this.on('ended', function() {
                        videojs.log('Awww...over so soon?!');
                        $this.closest('.slick').slick('next');
                        //console.log($this.closest('.slick'))
                    });
                });
        });

        
       
    }

}

function videoScroll(){
    let st = $elem.window.scrollTop();
    $('.module--video').each(function(){
        let $this = $(this);
        if(st >= $this.position().top - $elem.window.height() && st <= $this.position().top + $this.height()) {
            //let _id = $this.data('video-index');
            //players[_id].play();
            console.log('play');
            let $video = $this.find('video');
            $video[0].play();
       
        } else {
            //console.log(players[0]);
            //let _id = $this.data('video-index');
            //players[_id].pause();
            console.log('pause');
            let $video = $this.find('video');
            $video[0].pause();
        }
    });
}

function pauseVideos(){
    console.log('pause videos')
    $('video').each(function(){
        $(this)[0].pause();
    });
    return true;
    $('.module--video').each(function(){
        let $this = $(this);
        let $video = $this.find('video');
            $video[0].pause();
    });
}

function playVideos(){
    //console.log('======= playVideos =======')
    videoScroll();
    //custom for homepage
    if( $('[data-barba-namespace="home"]').length > 0 ) {
        $('videos').each(function(){
            
            let $video = $(this);
           // $video[0].play();
            $video.addEventListener("canplay", function(){
             //   $video[0].play();
                //console.log('loadstart can play')
            });
            
        });
    }
}

function initFades(){
    $('[data-fade-in]').addClass('fade-in');
}

function initFastClick(){
    if ('addEventListener' in document) {
        document.addEventListener('DOMContentLoaded', function() {
            FastClick.attach(document.body);
        }, false);
    }
}

function initCanvas(){
    return true;
    console.log('init html2canvas');

    // Get handles on the video and canvas elements
		var video = $('.slick-active').find('video')[0];
		var canvas = document.querySelector('canvas');
		// Get a handle on the 2d context of the canvas element
        var context = canvas.getContext('2d');
        
		// Define some vars required later
		var w, h, ratio;
		
		ratio = video.videoWidth / video.videoHeight;
			// Define the required width as 100 pixels smaller than the actual video's width
			w = window.innerWidth;
			// Calculate the height based on the video's width and the ratio
			h = window.innerHeight;
			// Set the canvas width and height to the values just calculated
			canvas.width = window.innerWidth;
            canvas.height = window.innerHeight;	
        
        snap();
        $('[data-template="home"]').hide();
        
		
		// Takes a snapshot of the video
		function snap() {
			// Define the size of the rectangle that will be filled (basically the entire element)
			context.fillRect(0, 0, w, h);
            // Grab the image from the video
            context.filter = "blur(10px)";
			context.drawImage(video, 0, 0, w, h);
        }
        
    let options = {
        foreignObjectRendering: true,
        backgroundColor: '#ffff'
    }
    /*
    html2canvas(document.body, options).then(function(canvas) {
        document.body.appendChild(canvas);
    });
    */
}

function destroyCanvas(){
    if($elem.main.find('canvas').length > 0){
        $elem.main.find('canvas').remove();
    }
}

function cloneMain(){
    $clone = $elem.main.find('.main-feature');
    $clone.addClass('cloned'); 
}
function removeClone(){
    
}

function appendMain(){
    $('body').append($clone);
}

function initWindow(){
    winResize();
    $elem.window.on('resize', $.throttle( 100, winResize ));
}



function winResize(){
  
    isMobile = $elem.window.width() < 768 ? true : false;
    mobile_header = isMobile ? $elem.MobileMenu.height() : 0;
    margin = isMobile ?  20 : 30;
    $elem.Logo.css('height', $elem.window.width() * .245);
    $elem.LogoDummy.css('height', $elem.window.width() * .25);

    setHubLogo();

    $elem.body.addClass('resizing');

    clearTimeout(winResizeTimer);
    if($stickybits != undefined) $stickybits.update();
    winResizeTimer = setTimeout(function(){
        setHubLogo();
        initSVG();
        $elem.body.removeClass('resizing');
        if($('videos').length > 0) {
            objectFitVideos();
        }
    }, 300);
    if($('[data-barba-namespace="project"]').length > 0) {
        $('[data-barba-namespace="project"]').css('height', $elem.window.innerHeight());
        return true;
        if($('video[data-size="contain"]').length > 0) {
            //size project contained videos
            $('video[data-size="contain"]').each(function(){
                let $this = $(this);
                let newHeight = Math.round($this.width() / $this.data('ratio'));
                let $container = $this.parent();
                if(newHeight < $container.height()){
                    $this.css('height', newHeight);
                } else {
                    $this.css('height',  '');
                }
                
            });
        }
    }
    
    
    if($('[data-template="home"]').length > 0)
    $('[data-template="home"]').css('height', $elem.window.height());

    if($('[data-template="person"]').length > 0) {
        pushPeople($('.list-li.active .list--accordion'));
    }

    //reflection
    if($('[data-template="reflections"]').length > 0 || $('[data-template="reflection"]').length > 0){
        //$elem.main.find('.slick').css('height', $elem.main.find('.slick').width() * .75);
    }

    //init studio
    if($('[data-template="studios"]').length > 0){
        $('[data-template="studios"] svg').css('height', $elem.window.width() * .25);
        //$('[data-template="studios"] .slick').css('height', $elem.window.width() * .25);
        //$('[data-template="studios"] .slick figure').css('height', $elem.window.width() * .25);
        
    }        

    
}

function initLazyLoad(){
    window.lazySizesConfig = window.lazySizesConfig || {};

    // use .lazy instead of .lazyload
    //window.lazySizesConfig.lazyClass = 'lazy';

    // use data-original instead of data-src
    lazySizesConfig.srcAttr = 'data-original';

    //page is optimized for fast onload event
    lazySizesConfig.loadMode = 2;

    lazySizesConfig.expand = 900;

    lazySizesConfig.expFactor = 3;

    $(document).on('lazybeforeunveil', function(){
        $('.slick-initialized').slick('resize');
        $('.slick-initialized').slick('setDimensions');
    });
    
}